import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Abda Rafi | Portfolio', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'My portfolio site.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is ',
  name: 'Abda Rafi.',
  subtitle: 'I love Information Technology!',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne:
    'My fullname is Abda Rafi Hamaminata. I am graduated from Computer Engineering, Universitas Diponegoro in 2020. I am passionate in learning Software Development and DevOps Technology. I have experienced with Python and the other IT-related technologies such as Network Infrastructure, Virtualization, and Computer Security for over 3 years.',
  paragraphTwo:
    'During my study, I had participated in organization and club related with technology. Moreover, I was selected as Djarum Beasiswa Plus 2018/2019 Awardee, a scholarship from Djarum Foundation that was given to outstanding students for their academic and organization skills. I have learned leadership, teamwork, good communication, and problem-solving through the one-year program that provided by Djarum Foundation.',
  paragraphThree:
    'Beside that, I was actively participated in IT competitions and have got several achievements.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'autonetapi.png',
    title: 'AUTONETAPI',
    info: 'Automate Cisco CSR1000V Network Configuration with RESTconf',
    info2: 'Developed network automation web application using REST API and Django framework.',
    url: '',
    repo: 'https://github.com/abdarafi/autonetapi-csr1000v', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'drop.png',
    title: 'DROP',
    info:
      'Parking Reservation System Concept with Implementation of Integrated AI Vision to Mobile Apps',
    info2: 'I bring this project to the final of GEMASTIK 12 in Telkom University.',
    url: '',
  },
  {
    id: uuidv1(),
    img: 'unibin.png',
    title: 'UNIBIN',
    info:
      'Uniquely Beneficial Bin Integrating (UNIBIN) is a prototype that refers to an integrated system between smart bin and smartphone. The smart bin have sensors in a microcontroller to detect the incoming waste. The sensors will transfer the information of the waste to a smartphone  that integrated with the UNIBIN. The information is collected on the smartphone and it turned into some points that earned by the smartphone user. The points could be redeemed for cash or a voucher.',
    info2:
      'From this idea, I and my team got a silver medal for category Technology, IoT, and Apps on AIGC Singapore 2019.',
    url: '',
  },
  {
    id: uuidv1(),
    img: 'alihan.png',
    title: 'ALIHAN',
    info: ' Mobile Application Prototype for village area translocation management',
    info2:
      'I bring this project to the final of Find IT 2019 - UX Design Competition in Gadjah Mada University.',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'abdarafihamaminata@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/abdarafi06',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/abdarafi/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/abdarafi',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
